import axios from "@/utils/request";
import publicPath from "@/utils/public";
import qs from "qs";

/**
 * 获取首页轮播图
 * @param params 查询条件
 */
export async function SelectPublicityPictures(params) {
  const res = await axios.get("/api/HomePage/SelectPublicityPictures", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取资讯动态
 * @param params 查询条件
 */
export async function GetNewsInfo(params) {
  const res = await axios.get("/api/HomePage/GetNewsInfo", {
    params: params,
  });
  if (res.data.code === 100) {
    const list = {
      count: res.data.count,
      list: JSON.parse(publicPath.Decrypt(res.data.data)),
    };
    return list;
  }
  if (res.data.code === 400) {
    return [];
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取资讯动态详情
 * @param params 查询条件
 */
export async function GetNewsContent(params) {
  const res = await axios.get("/api/HomePage/GetNewsContent", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取客户案例
 * @param params 查询条件
 */
export async function GetCaseInfo(params) {
  const res = await axios.get("/api/HomePage/GetCaseInfo", {
    params: params,
  });
  if (res.data.code === 100) {
    const list = {
      count: res.data.count,
      list: JSON.parse(publicPath.Decrypt(res.data.data)),
    };
    return list;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取合作伙伴
 * @param params 查询条件
 */
export async function GetConnection(params) {
  const res = await axios.get("/api/HomePage/GetConnection", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.result));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取基础信息
 * @param params 查询条件
 */
// export async function GetSystemManger(params) {
//   const res = await axios.get("/api/HomePage/GetSystemManger", {
//     params: params,
//   });
//   if (res.data.code === 100) {
//     return JSON.parse(publicPath.Decrypt(res.data.data));
//   }
//   return Promise.reject(new Error(res.data.message));
// }

/**
 * 获取产品信息
 * @param params 查询条件
 */
export async function GetProductList(params) {
  const res = await axios.get("/api/HomePage/GetProductList", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取荣誉资质
 * @param params 查询条件
 */
export async function GetEndowments(params) {
  const res = await axios.get("/api/HomePage/GetEndowments", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取客服人员
 * @param params 查询条件
 */
export async function GetCustomerServiceList(params) {
  const res = await axios.get("/api/HomePage/GetCustomerServiceList", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取资讯动态详情
 * @param params 查询条件
 */
export async function SelectNewsInfoList(params) {
  const res = await axios.get("/api/HomePage/SelectNewsInfoList", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 获取客户案例详情
 * @param params 查询条件
 */
export async function SelectCaseInfoList(params) {
  const res = await axios.get("/api/HomePage/SelectCaseInfoList", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 提交需求
 * @param data 查询条件
 */
export async function AddMessage(data) {
  const res = await axios.post("/api/HomePage/AddMessage", qs.stringify(data));
  if (res.data.code === 100) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 资讯动态浏览
 * @param data 查询条件
 */
export async function UpdateNewsInfoTotal(data) {
  const res = await axios.post(
    "/api/HomePage/UpdateNewsInfoTotal",
    qs.stringify(data)
  );
  if (res.data.code === 100) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 客户案例浏览
 * @param data 查询条件
 */
export async function UpdateCaseInfoTotal(data) {
  const res = await axios.post(
    "/api/HomePage/UpdateCaseInfoTotal",
    qs.stringify(data)
  );
  if (res.data.code === 100) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 字典接口
 * @param params 查询条件
 */
export async function SelectDictionary(params) {
  const res = await axios.get("/api/Dictionary/SelectDictionary", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

export async function SelectDicContent(params) {
  const res = await axios.get("/api/Dictionary/SelectDicContent", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 查询基础信息
 * @param
 */
export async function GetSystemManger() {
  const res = await axios.get("/api/Systemmanger/GetSystemManger");
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 查询名片信息
 * @param
 */
export async function selectSingleCardInfo(params) {
  const res = await axios.get("/api/CardInfo/selectSingleCardInfo", {
    params: params,
  });
  if (res.data.code === 100) {
    return JSON.parse(publicPath.Decrypt(res.data.data));
  }
  return Promise.reject(new Error(res.data.message));
}
