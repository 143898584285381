<template>
  <div class="cultivate">
    <div class="content">
      <div class="content-title">鸿新云在线学习平台</div>
      <div class="content-msg">
        由重庆鸿新在线信息技术有限公司开发，面向职业教培领域，专注于职业技能、技术人才培养的在线学习平台。平台有丰富的课程和题库资源，精彩的视频学习、海量的题库练习，汇集200+职业(工种)、3000+精选优质课程资源；为学员创造最佳的学习体验。
        <div class="content-msg">
          鸿新云致力于提供职业教育一站式学习平台，是提升技能知识、提高职业技能的专业平台。
        </div>
      </div>
      <div class="QRCodeBox">
        <div class="QRCodeList">
          <img src="../assets/鸿新云公众号_二维码.png" alt="" />
          <div class="QRCodeText">鸿新云公众号</div>
        </div>
        <div class="QRCodeList">
          <img src="../assets/鸿新云小程序_二维码.png" alt="" />
          <div class="QRCodeText">鸿新云小程序</div>
        </div>
      </div>
      <div class="flex-box">
        <div class="flex-box-inset">
          <p class="p1">内容形式多样</p>
          <p class="p2">讲义、视频、练习、测试等</p>
          <img src="../assets/内容形式多样.png" alt="" />
        </div>
        <div class="flex-box-inset">
          <p class="p1">课程全面</p>
          <p class="p2">海量题库，进入鸿新云公众号即能开始学习</p>
          <img src="../assets/课程全面.png" alt="" />
        </div>
        <div class="flex-box-inset">
          <p class="p1">无场景限制</p>
          <p class="p2">充分利用碎片时间，随时随地学习</p>
          <img src="../assets/无场景限制.png" alt="" />
        </div>
        <div class="flex-box-inset">
          <p class="p1">无设备限制</p>
          <p class="p2">
            使用手机、电脑、平板随时随机学习，“一机在手，学习无忧”
          </p>
          <img src="../assets/无设备限制.png" alt="" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-title">核心功能</div>
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="多样化培训方式" name="1">
            <el-tabs
              v-model="activeName1"
              tab-position="left"
              style="height: 400px"
            >
              <el-tab-pane name="1"
                ><span slot="label"
                  ><img src="../assets/在线课程.png" alt="" /> 在线课程</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">在线课程</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      不限容量课程库，自由设定课程分类
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      随时随地学习，知识技能触手可及
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2"
                ><span slot="label"
                  ><img src="../assets/学习计划.png" alt="" /> 学习计划</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">学习计划</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      集线上线下课程/作业/考试/导师辅导为一体的综合性学习任务
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      脉络清晰，层次分明
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      自动化推送，让学员第一时间获取所需内容
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="3"
                ><span slot="label"
                  ><img src="../assets/o2o混合培训.png" alt="" />
                  O2O混合培训</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">O2O混合培训</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      线上线下学考练一体，打造多元化的培训内容
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      在线报名/通知/签到等功能，满足精细化学员管理
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="4"
                ><span slot="label"
                  ><img src="../assets/直播课堂.png" alt="" /> 直播课堂</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">直播课堂</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      多种教学工具与直播互动方式，让课堂生动有趣
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      自动录播，一键生成在线课程，让做课轻而易举
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="5"
                ><span slot="label"
                  ><img src="../assets/线下培训.png" alt="" /> 线下培训</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">线下培训</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      培训开班、通知、协议签署一站式解决
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学员签到等数字化管理，让培训经理有迹可循
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="智能化便捷运营" name="2">
            <el-tabs
              v-model="activeName1"
              tab-position="left"
              style="height: 400px"
            >
              <el-tab-pane name="1"
                ><span slot="label"
                  ><img src="../assets/多端多角色.png" alt="" />
                  多端多角色专属视图，学习管理高效便捷</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">多端多角色专属视图，学习管理高效便捷</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学习管理：学习情况随时查看，培训进度尽在掌握
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学员学习：界面布局清晰明了，功能入口操作简单
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2"
                ><span slot="label"
                  ><img src="../assets/智能运营工具.png" alt="" />
                  智能运营工具，摆脱重复性工作</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">智能运营工具，摆脱重复性工作</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      在线课程一键生成课程封面，提升工作效率
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      面授课程集中管理，一次配置，重复引用
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      在线学习计划配置规则，自动推送给规则内学员
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      在线考试题库、试卷库，试卷无需重复编写，支持多规则自动组卷
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="3"
                ><span slot="label"
                  ><img src="../assets/数据安全.png" alt="" />
                  培训规则随心配，数据安全有保障</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">培训规则随心配，数据安全有保障</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学习任务设定可见范围，保密资源不泄露
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学员学习防录屏，有效抵御数据外流风险
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      管理员权限自由配置，层层验证，范围清洗
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="全方位学习激励" name="3">
            <el-tabs
              v-model="activeName1"
              tab-position="left"
              style="height: 400px"
            >
              <el-tab-pane name="1"
                ><span slot="label"
                  ><img src="../assets/激励体系.png" alt="" />
                  全面的激励体系</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">全面的激励体系</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      学习、授课或参与互动均可获得学习积分
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      完成学习任务，可获得特定在线勋章奖励
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      支持在线向学员发放学习证书
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2"
                ><span slot="label"
                  ><img src="../assets/学习排行.png" alt="" />
                  令人瞩目的学习排行</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">令人瞩目的学习排行</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      展示学员学习排行榜，学习动态一目了然
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      展示学员学习积分榜，调动学员好胜心
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="3"
                ><span slot="label"
                  ><img src="../assets/游戏化学习.png" alt="" />
                  趣味横生的游戏化学习</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">趣味横生的游戏化学习</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      寓教于乐，在趣味闯关中学习知识
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      活泼精美的动画设计，打造轻松愉快的学习氛围
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="精细化数据分析" name="4">
            <el-tabs
              v-model="activeName1"
              tab-position="left"
              style="height: 400px"
            >
              <el-tab-pane name="1"
                ><span slot="label"
                  ><img src="../assets/数据闭环.png" alt="" />
                  全流程数据闭环管理</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">全流程数据闭环管理</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      任务进度、学员档案、收口记录等信息
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      培训进展清洗展示
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      多项数据分析报表支撑，覆盖培训运营全流程
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      数据明细一网打尽
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2"
                ><span slot="label"
                  ><img src="../assets/数据分析.png" alt="" />
                  学习数据智能分析</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">学习数据智能分析</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      问卷结果，考试成绩智能分析
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      重要数据一目了然，省心省力
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="3"
                ><span slot="label"
                  ><img src="../assets/可视化报表.png" alt="" />
                  可视化报表自由可配</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">可视化报表自由可配</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      内置报表设计器，个性报表自由配置
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      随时随地可学，知识技能触手可及
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="4"
                ><span slot="label"
                  ><img src="../assets/数据变动.png" alt="" />
                  数据变动尽在掌握</span
                >
                <div class="tab-content">
                  <img class="img" src="../assets/内容图片.png" alt="" />
                  <img class="img1" src="../assets/文字下方遮罩层.png" alt="" />
                  <div class="tab-content-title">
                    <p class="p1">数据变动尽在掌握</p>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      支持开启数据订阅功能
                    </div>
                    <div class="tab-content-msg">
                      <div class="point-out">
                        <div class="point-inset"></div>
                      </div>
                      数据更新自动通知，随时掌握数据动态
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content">
      <div class="content-title content-title1">
        个性化系统设置，学习平台品牌自由
      </div>
      <div class="content-title content-title2">
        高度自定义，无需定制，随时修改培训平台机构信息
      </div>
      <div class="content-flex">
        <div class="content-flex-box">
          <img src="../assets/企业信息设置.png" alt="" />
          <p class="p1">企业信息设置</p>
          <p class="p2">企业logo、企业slogan、专属域名、23项企业设置</p>
        </div>
        <div class="content-flex-box">
          <img src="../assets/个性化设置.png" alt="" />
          <p class="p1">个性化设置</p>
          <p class="p2">
            图标设置、图片设置、模块设置、颜色设置、自定义消息内容
          </p>
        </div>
        <div class="content-flex-box">
          <img src="../assets/储存管理.png" alt="" />
          <p class="p1">储存管理</p>
          <p class="p2">素材库、知识库、独立课件服务器</p>
        </div>
        <div class="content-flex-box">
          <img src="../assets/学习配置.png" alt="" />
          <p class="p1">学习配置</p>
          <p class="p2">专业设置、课程设置、试题设置、计划设置</p>
        </div>
      </div>
    </div>
    <div class="content content1">
      <div class="content-title content-title3">
        服务始终如一，工匠精神成就品牌
      </div>
      <div class="content-title content-title4">
        我们始终坚持“以客户为中心”，听客户所需，想客户所想。希望以优质的服务和品牌实力，助力客户提高培训效率，让培训更简单。
      </div>
    </div>
    <div class="content-line">
      <img
        class="content-line-inset"
        src="../assets/服务理念22.png.png"
        alt=""
      />
    </div>
    <div class="content">
      <div class="content-title content-title1">
        化繁为简，减少客户对培训平台的学习成本
      </div>
      <div class="content-title content-title2">
        拒绝复杂操作对客户产生的负担
      </div>
      <div class="content-box">
        <div class="content-box-inset">
          <img src="../assets/多终端学习.png" alt="" />
          <div class="content-box-inset-right">
            <p class="p1">多终端学习</p>
            <p class="p2">
              支持微信H5、电脑网页、APP、企业微信、钉钉、飞书等多端直接使用，灵活便捷
            </p>
          </div>
        </div>
        <div class="content-box-inset">
          <img src="../assets/小白式操作.png" alt="" />
          <div class="content-box-inset-right">
            <p class="p1">小白式操作</p>
            <p class="p2">
              前后台功能操作便捷，各个功能逻辑清晰，极大降低人员学习成本
            </p>
          </div>
        </div>
        <div class="content-box-inset">
          <img src="../assets/3000+精品课程.png" alt="" />
          <div class="content-box-inset-right">
            <p class="p1">3000+精品课程</p>
            <p class="p2">
              110+课程分类，覆盖多岗位、多行业，对企业自建培训课程体系进行补充
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content content2">
      <div class="content-title content-title3">多重保障，稳定高效</div>
      <el-tabs class="tab" v-model="activeName2">
        <el-tab-pane label="运营数据" name="1">
          <div class="content-tab">
            <div class="content-tab-box">
              <img src="../assets/服务机构.png" alt="" />
              <p class="p1">500+</p>
              <p class="p2">服务机构</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/服务人数.png" alt="" />
              <p class="p1">500000+</p>
              <p class="p2">服务人数</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/课程资源.png" alt="" />
              <p class="p1">1500+</p>
              <p class="p2">课程资源</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/考试人数.png" alt="" />
              <p class="p1">300000+</p>
              <p class="p2">考试人数</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/学习人数.png" alt="" />
              <p class="p1">200000+</p>
              <p class="p2">学习人数</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="安全保障" name="2">
          <div class="content-tab content-tab1">
            <div class="content-tab-box">
              <img src="../assets/三级等保.png" alt="" />
              <p class="p1">三级等保</p>
              <p class="p2">已到达国家对非银行单位最高的安全标准</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/物理安全防护.png" alt="" />
              <p class="p1">物理安全防护</p>
              <p class="p2">腾讯云VPC专有网络独立内网，严格的SLA等级保护</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/数据自动备份.png" alt="" />
              <p class="p1">数据自动备份</p>
              <p class="p2">数据信息定期自动备份，云防护设备和措施</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/安全管理制度.png" alt="" />
              <p class="p1">安全管理制度</p>
              <p class="p2">规范的安全管理制度，严格的管理权限认证</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="技术和服务" name="3">
          <div class="content-tab content-tab2">
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标1.png" alt="" />
              <p class="p3">高并发及服务器集群运维能力</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标5.png" alt="" />
              <p class="p3">SaaS版和混合云版系统免费升级</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标2.png" alt="" />
              <p class="p3">第三方系统及应用的集成能力</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标6.png" alt="" />
              <p class="p3">1V1资讯辅导、7x12小时及时响应</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标3.png" alt="" />
              <p class="p3">开放的生态环境，共享资源，提供更多可能</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标7.png" alt="" />
              <p class="p3">产品使用帮助、系统技术维护</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标4.png" alt="" />
              <p class="p3">先进的系统研发技术、AI人工智能技术</p>
            </div>
            <div class="content-tab-box">
              <img src="../assets/技术服务-图标8.png" alt="" />
              <p class="p3">专属实施、运营服务及项目支持</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content">
      <div class="content-title content-title1">
        已有200+客户选择我们，每个客户的选择都是一份肯定
      </div>
      <div class="content-title content-title2">
        覆盖企业内训、教育培训、自媒体、内容电商、学校等众多领域
      </div>
      <vue-seamless-scroll
        v-if="scrollList.length >= 1"
        :data="scrollList"
        :class-option="defaultOption"
        class="scroll"
      >
        <ul class="partner-box">
          <li
            class="partner-box-flex"
            v-for="(item, index) in scrollList"
            :key="index"
          >
            <img class="img" src="../assets/矩形 1.png" alt="" />
            <img
              class="img1"
              v-if="item.connLogo"
              :src="$path.path + '/src/customerFile/' + item.connLogo"
              alt=""
            />
            <p>{{ item.connName }}</p>
          </li>
        </ul>
      </vue-seamless-scroll>
      <!-- <vue-seamless-scroll
            v-if="scrollList1.length >= 1"
            :data="scrollList1"
            :class-option="defaultOption1"
            class="scroll"
          >
            <ul class="partner-box">
              <li
                class="partner-box-flex"
                v-for="(item, index) in scrollList1"
                :key="index"
              >
                <img class="img" src="../assets/矩形 1.png" alt="" />
                <img class="img1" v-if="item.connLogo" :src="$path.path + '/src/customerFile/' + item.connLogo" alt="" />
                <p>{{ item.connName }}</p>
              </li>
            </ul>
          </vue-seamless-scroll> -->
    </div>
  </div>
</template>
<script>
import { GetConnection } from "../api/home";
export default {
  name: "Cultivate",
  data() {
    return {
      activeName: "1",
      activeName1: "1",
      activeName2: "1",
      scrollList: [],
      scrollList1: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName1 = "1";
    },
    getData() {
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.content {
  /* padding: 0 10%; */
  width: 1280px;
  margin: 0 auto;
}
.content .content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin: 60px 0;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin: 60px 0;
  color: rgba(211, 26, 26, 1);
}
.content .content-title1 {
  margin: 60px 0 30px 0;
}
.content .content-title2 {
  margin: 0 0 60px 0;
  font-size: 18px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.content .content-title3 {
  margin: 0 0 30px 0;
  color: #fff;
}
.content .content-title4 {
  margin: 0 0 60px 0;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.content .content-msg {
  text-indent: 2em;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.content .QRCodeBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 200px 0;
  box-sizing: border-box;
}
.content .QRCodeBox .QRCodeList {
  display: flex;
  flex-direction: column;
  width: 270px;
  justify-content: center;
  align-items: center;
}
.content .QRCodeBox .QRCodeList:first-child {
  margin-right: 34px;
}
.content .QRCodeBox .QRCodeList:last-child {
  margin-left: 34px;
}
.content .QRCodeBox .QRCodeList img {
  display: block;
  width: 50%;
}
.content .QRCodeBox .QRCodeList .QRCodeText {
  font-size: 16px;
  margin-top: 10px;
  color: rgba(102, 102, 102, 1);
}

>>> .el-tabs__nav-wrap::after {
  height: 0 !important;
}
.el-tabs--top >>> .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.el-tabs--top >>> .el-tabs__nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.el-tabs--top >>> .el-tabs__item {
  height: 60px;
  line-height: 60px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.el-tabs--top >>> .el-tabs__item.is-active {
  color: rgba(227, 25, 25, 1);
  border-bottom: 2px solid #e31919;
}
.el-tabs--top >>> .el-tabs__item:hover {
  color: rgba(227, 25, 25, 1);
}
.el-tabs--top >>> .el-tabs__active-bar {
  height: 0;
  background-color: #e31919;
}
.el-tabs--top >>> .el-tabs__content {
  padding-top: 40px;
}
.el-tabs--left >>> .el-tabs__content {
  padding-top: 0;
}
.tab >>> .el-tabs__content {
  padding-top: 0;
}
.el-tabs--left >>> .el-tabs__active-bar {
  width: 0 !important;
}
.el-tabs--left >>> .el-tabs__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}
.el-tabs--left >>> .el-tabs__item {
  height: 80px;
  padding: 0 20px;
  width: 400px;
  display: flex !important;
  align-items: center !important;
}
.el-tabs--left >>> .el-tabs__item span {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.el-tabs--left >>> .el-tabs__item span img {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
.el-tabs--left >>> .el-tabs__item.is-active {
  border-bottom: 0;
  background: linear-gradient(90deg, #fff0f0 0%, #ffffff 100%);
}
.el-tabs--left >>> .el-tabs__item:hover {
  border-bottom: 0;
  background: linear-gradient(90deg, #fff0f0 0%, #ffffff 100%);
}
.tab-content {
  width: 100%;
  height: 400px;
  position: relative;
}
.tab-content .img {
  position: absolute;
  width: 100%;
  height: 100%;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: 1;
}
.tab-content .img1 {
  position: absolute;
  width: 100%;
  height: 100%;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: 2;
}
.tab-content .tab-content-title {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 3;
  color: #fff;
}
.tab-content .tab-content-title .p1 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.tab-content .tab-content-title .tab-content-msg {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.tab-content .tab-content-title .tab-content-msg .point-out {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  margin-right: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out .point-inset {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.content-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.content-flex .content-flex-box {
  width: 19%;
  height: 360px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #ccc;
}
.content-flex .content-flex-box img {
  width: 120px;
  height: 120px;
}
.content-flex .content-flex-box .p1 {
  font-size: 24px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-flex .content-flex-box .p2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content1 {
  height: 220px;
  padding: 60px 0;
  width: 100%;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-line {
  width: 80%;
  margin: 0 auto;
  height: 380px;
  background: #fff;
  margin-top: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-line-inset {
  /* width: calc(100% - 160px);
  height: 260px; */
  width: 80%;
}
.content-box {
  width: 70%;
  margin: 0 auto;
}
.content-box .content-box-inset {
  display: flex;
  align-items: center;
  padding: 25px 0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
}
.content-box .content-box-inset img {
  width: 50px;
  height: 50px;
  margin: 0 30px;
}
.content-box .content-box-inset .content-box-inset-right {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-box .content-box-inset .content-box-inset-right .p1 {
  font-size: 24px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-box .content-box-inset .content-box-inset-right .p2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.content2 {
  height: 500px;
  padding: 60px 0;
  margin-top: 60px;
  width: 100%;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px 0;
  background: #fff;
}
.content-tab .content-tab-box {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-tab .content-tab-box img {
  width: 90px;
  height: 90px;
}
.content-tab .content-tab-box .p1 {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-top: 30px;
  margin-bottom: 15px;
}
.content-tab .content-tab-box .p2 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.tab {
  width: 1280px;
  margin: 0 auto;
}
.tab >>> .el-tabs__item {
  color: #fff;
}
.tab >>> .el-tabs__item.is-active {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.tab >>> .el-tabs__item:hover {
  color: #fff;
}
.tab >>> .el-tabs__nav-wrap::after {
  height: 2px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.content-tab1 {
  padding: 55px 40px;
}
.content-tab2 {
  height: 260px;
  padding: 20px 60px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-tab2 .content-tab-box {
  width: 50%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-tab2 .content-tab-box img {
  width: 18px;
  height: 18px;
  margin-right: 18px;
}
.content-tab2 .content-tab-box .p3 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.scroll {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 240px;
  height: 170px;
  position: relative;
  margin-right: 20px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 248px;
  height: 147px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 15px;
  left: 20px;
  /* width: 200px;
  height: 70px; */
  width: 208px;
  height: 117px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.flex-box {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset {
  padding-bottom: 20px;
  width: 270px;
  height: 360px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  position: relative;
  align-items: center;
}
.flex-box .flex-box-inset .p1 {
  align-self: start;
  padding: 0 20px;
  font-size: 24px;
  line-height: 84px;
  color: rgba(51, 51, 51, 1);
}
.flex-box .flex-box-inset .p2 {
  align-self: start;
  padding: 0 20px;
  font-size: 16px;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.flex-box .flex-box-inset img {
  width: 230px;
  height: 200px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
</style>
