<template>
  <div class="MobileElectronicCardWrap">
    <div class="electronicCardWrap" ref="electronicCardWrap" id="electronicCardWrap">
      <div class="cardTopWrap">
        <el-image v-if="cardInfoImageUrl!= ''" :src="cardInfoImageUrl"></el-image>
        <div class="cardInfoWrap" ref="cardInfoWrap" id="cardInfoWrap" v-else>
          <div class="avatorWrap">
            <!-- $path.path + '/src/cardPhoto/' + dataObj.cardPhoto -->
            <img v-if="dataObj.cardPhoto" :src="'data:image/png;base64,' + dataObj.cardPhoto">
          </div>
          <div class="logoWrap"><img src="@/assets/MobileElectronicCard/icon_logo.png"></div>
          <div class="codeWrap">
            <!-- $path.path + '/src/cardFile/' + dataObj.cardFile -->
            <img v-if="dataObj.cardFile" :src="'data:image/png;base64,' + dataObj.cardFile">
          </div>
          <div class="name">{{ dataObj.cardName }}<span>{{ dataObj.cardPost }}</span></div>
          <div class="textWrap">
            <span class="phone">{{ dataObj.cardPhone }}</span>
            <span class="email">{{ dataObj.cardEmail }}</span>
          </div>
        </div>
        <div class="cardCodeWrap">
          <div class="text01">微信长按识别添加</div>
          <div class="imgWrap"><img src="@/assets/MobileElectronicCard/er_code.png"></div>
          <div class="text02Wrap" @click="jumpGuanwang"><span>点击跳转至鸿新在线官网</span></div>
        </div>
      </div>
      <div class="cardMainWrap">
        <div class="otherInfoWrap">
          <div class="text text01">{{ dataObj.cardPhone }}<a class="call" :href="'tel:' + dataObj.cardPhone">拨打</a></div>
          <div class="text text02">{{ dataObj.cardWeiXin }}<span class="copy" @click="textCopy(dataObj.cardWeiXin)">复制</span></div>
          <div class="text text03">{{ dataObj.cardDepartment }}</div>
          <div class="text text04">{{ dataObj.cardPost }}</div>
          <div class="text text05">重庆鸿新在线信息技术有限公司</div>
          <div class="text text06" @click="navigateToAmap">重庆市九龙坡区经纬大道1409号7栋7-2</div>
        </div>
      </div>
    </div>
    <div class="btnWrap">
      <div v-if="!isWechat()" class="btn" @click="saveImg">保存至本地</div>
      <!--<div class="btn" @click="shareCardInfo">分享给朋友</div>-->
    </div>
  </div>
</template>
<script type="text/javascript">
  import html2canvas from 'html2canvas';
  import { selectSingleCardInfo } from "../api/home";
  // import { loadJS } from "@/utils/wmtools";
  export default {
    name: "selectSingleCardInfo",
    data() {
      return {
        dataObj: {},
        cardInfoImageUrl: ''
      };
    },
    methods: {
      shareCardInfo() {
        if (!navigator.share) {
        alert("您当前浏览器不支持分享！");
        } else {
          navigator.share({
             title: this.dataObj.cardName + '-个人名片',
             text: this.dataObj.cardName + '-个人名片',
             url: window.location.href
          });
        }
      },
      navigateToAmap() {
        // 设置起点和终点的坐标
        // const startLatLng = '106.468498,29.527385';
        const endLatLng = '106.462555,29.521527';
        // 使用高德地图的导航Web服务
        const amapUrl = `https://uri.amap.com/marker?position=${endLatLng}&name=到达位置&key=你的高德key&dev=0&t=0`;
        // 打开新窗口进行导航
        window.open(amapUrl, '_blank');
      },
      // 赋值
      textCopy(t) {
        let _that = this;
        // 如果当前浏览器版本不兼容navigator.clipboard
        if (!navigator.clipboard) {
          var ele = document.createElement('input');
          ele.value = t;
          document.body.appendChild(ele);
          ele.select();
          document.execCommand('copy');
          document.body.removeChild(ele);
          if (document.execCommand('copy')) {
            _that.$message.success('复制成功！');
          } else {
            _that.$message.error('复制失败！');
          }
        } else {
          navigator.clipboard
            .writeText(t)
            .then(function () {
              _that.$message.success('复制成功！');
            })
            .catch(function () {
              _that.$message.error('复制失败！');
            });
        }
      },
      // 跳转至官网
      jumpGuanwang() {
        window.location.href = "/#/";
      },
      getData(cardId) {
        // '0ad6ba3cb05aca80bc70d5713df495ac'
        this.cardInfoImageUrl = '';
        selectSingleCardInfo({cariD: cardId})
          .then((res) => {
            this.dataObj = res[0];
            document.title = res[0].cardName + '-个人名片';
            setTimeout(()=>{
              this.$nextTick(() => {
                  this.saveCardImg();
              });
            },1000);
          })
          .catch((err) => {});
      },
      isWechat() {
        return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
      },
      saveCardImg() {
        const element = document.getElementById('cardInfoWrap');
        // const element = this.$refs.cert01Wrap; // 替换为你要生成图片的元素ID
        html2canvas(element).then((canvas) => {
          // 将Canvas转换为图片并保存为Blob对象
          // console.log(canvas.toDataURL('image/png'));
          this.cardInfoImageUrl = canvas.toDataURL('image/png');
          // console.log(this.cardInfoImageUrl);
          // let cardInfoImageUrl = canvas.toDataURL('image/png');
          // this.downloadImg(imageUrl);
          // const image = new Image();
          // image.src = canvas.toDataURL('image/png');
          // image.onload = () => {
          //   // 将Blob对象转换为URL并保存到imageUrl属性中
          //   this.imageUrl = URL.createObjectURL(new Blob([image.src], { type: 'image/png' }));
          // };
        });
      },
      saveImg(imageUrl) {
        const element = document.getElementById('electronicCardWrap');
        // const element = this.$refs.cert01Wrap; // 替换为你要生成图片的元素ID
        html2canvas(element).then((canvas) => {
          // 将Canvas转换为图片并保存为Blob对象
          // console.log(canvas.toDataURL('image/png'));
          this.imageUrl = canvas.toDataURL('image/png');
          let imageUrl = canvas.toDataURL('image/png');
          this.downloadImg(imageUrl);
          // const image = new Image();
          // image.src = canvas.toDataURL('image/png');
          // image.onload = () => {
          //   // 将Blob对象转换为URL并保存到imageUrl属性中
          //   this.imageUrl = URL.createObjectURL(new Blob([image.src], { type: 'image/png' }));
          // };
        });
      },
      downloadImg(imageUrl) {
        if (this.isWechat()) {
          // this.$message.success('请长按屏幕进行图片保存');
        } else {
          // console.log(imageUrl);
          let link = document.createElement("a");
          link.setAttribute("href", this.imageUrl);
          link.setAttribute("download", this.dataObj.cardName + '_' + this.dataObj.cardPhone + '_' + new Date().getTime() + '.png');
          link.click();
          // let _that = this;
          // let qrcode = document.getElementById('qrcode');
          // let img = qrcode.getElementsByTagName('img')[0];
          // img.onload = function() {
          //   // this.imgUrl = img.getAttribute("src");
          //   let link = document.createElement("a");
          //   link.setAttribute("href", imageUrl);
          //   link.setAttribute("download", _that.studentInfo.plexName + '_' + _that.studentInfo.pleaCode + '_'+ new Date().getTime() +'.png')
          //   link.click();
          // }
        }
      },
    },
    mounted() {
      if (this.$route.query.id) {
        this.getData(this.$route.query.id);
      }
      // if (this.isWechat()) {
      //   // this.$message.success('请长按屏幕进行图片保存');
      // }
      // loadJS("http://res2.wx.qq.com/open/js/jweixin-1.6.0.js", function() {
      //   //创建视频对象 livePusher变量我写在了data中 不再复制了 ，也可以直接在methods中直接声明变量
      //   console.log(1111);
      //   wx.checkJsApi({
      //     jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      //     success: function(res) {
      //       console.log(2222);
      //     // 以键值对的形式返回，可用的api值true，不可用为false
      //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      //     }
      //   });
      // });
    }
  };
</script>
<style type="text/css" scoped>
  .MobileElectronicCardWrap , .MobileElectronicCardWrap * {
    box-sizing: border-box;
  }
  .MobileElectronicCardWrap{
    min-height: 100vh;
    width: 100%;
    padding-bottom: 64px;
  }
  .MobileElectronicCardWrap .electronicCardWrap{
    padding: 20px 16px;
  }
  .MobileElectronicCardWrap .cardTopWrap{
    width: 100%;
    min-height: 406px;
    opacity: 1;
    border-radius: 8px;
    overflow: hidden;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap{
    padding: 121px 26px 15px 24px;
    background: url(~@/assets/MobileElectronicCard/bg01.png) no-repeat left center;
    background-size: 100% 400px;
    height: 200px;
    position: relative;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .avatorWrap{
    position: absolute;
    left: 28px;
    top: 23px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .avatorWrap img{
    width: 80px;
    height: 80px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .logoWrap{
    position: absolute;
    right: 20px;
    top: 17px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .logoWrap img{
    width: 106px;
    height: 32px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .codeWrap{
    position: absolute;
    right: 18px;
    top: 80px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .codeWrap img{
    width: 74px;
    height: 74px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .name{
    width: calc(100% - 80px);
    font-size: 28px;
    color: #333;
    line-height: 40px;
    height: 40px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .name span{
    font-size: 13px;
    color: #333;
    margin-left: 4px;
  }
  
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .textWrap{
    display: flex;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .textWrap .phone{
    width: 50%;
    font-size: 12px;
    color: #333;
    height: 28px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: url(~@/assets/MobileElectronicCard/icon_shouji_grey.png) no-repeat left center;
    background-size: 12px 12px;
    padding-left: 20px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardInfoWrap .textWrap .email{
    font-size: 12px;
    color: #333;
    height: 28px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: url(~@/assets/MobileElectronicCard/icon_email_grey.png) no-repeat left center;
    background-size: 12px 12px;
    padding-left: 20px;
  }
  
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap{

  }
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap .text01{
    font-size: 14px;
    color: #999;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap .imgWrap{
    width: 146px;
    height: 146px;
    overflow: hidden;
    margin: 0 auto 16px;
    padding-left: 3px;
    padding-top: 3px;
    background: url(~@/assets/MobileElectronicCard/er_code_border.png) no-repeat left center;
    background-size: 146px 146px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap .imgWrap img{
    width: 140px;
    height: 140px;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap .text02Wrap{
    text-align: center;
    border-top: 1px solid #F5F5F5;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
  .MobileElectronicCardWrap .cardTopWrap .cardCodeWrap .text02Wrap span{
    background: url(~@/assets/MobileElectronicCard/icon_browser.png) no-repeat left center;
    background-size: 14px 14px;
    padding-left: 20px;
    font-size: 14px;
    color: #2571F5;
  }

  .MobileElectronicCardWrap .cardMainWrap{

  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap{
    padding: 0 16px;
    margin-bottom: 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text{
    font-size: 14px;
    color: #333;
    height: 40px;
    line-height: 44px;
    padding-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text + .text {
    border-top: 1px solid #F5F5F5;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text01{
    background: url(~@/assets/MobileElectronicCard/icon_shouji_blue.png) no-repeat left center;
    background-size: 14px 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text02{
    background: url(~@/assets/MobileElectronicCard/icon_wechat_blue.png) no-repeat left center;
    background-size: 14px 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text03{
    background: url(~@/assets/MobileElectronicCard/icon_bumen.png) no-repeat left center;
    background-size: 14px 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text04{
    background: url(~@/assets/MobileElectronicCard/icon_job_blue.png) no-repeat left center;
    background-size: 14px 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text05{
    background: url(~@/assets/MobileElectronicCard/icon_gongsi.png) no-repeat left center;
    background-size: 14px 14px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text06{
    background: url(~@/assets/MobileElectronicCard/icon_dizhi.png) no-repeat left center;
    background-size: 14px 14px;
    color: #2571F5;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text01 a{
    background: url(~@/assets/MobileElectronicCard/icon_dianhua.png) rgba(37, 113, 245, 0.1) no-repeat 10px center;
    background-size: 14px 14px;
    padding-left: 30px;
    width: 64px;
    height: 26px;
    line-height: 26px;
    opacity: 1;
    font-size: 12px;
    border-radius: 16px;
    color: #2571F5;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -13px;
  }
  .MobileElectronicCardWrap .cardMainWrap .otherInfoWrap .text.text02 span{
    background: url(~@/assets/MobileElectronicCard/icon_fuzhi.png) rgba(237, 121, 5, 0.1) no-repeat 10px center;
    background-size: 14px 14px;
    padding-left: 30px;
    width: 64px;
    height: 26px;
    line-height: 26px;
    opacity: 1;
    border-radius: 16px;
    font-size: 12px;
    color: #ED7905;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -13px;
  }
  .MobileElectronicCardWrap .btnWrap{
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 18px;
  }
  .MobileElectronicCardWrap .btn{
    /*width: calc(50% - 18px);*/
    flex: 1;
    margin: 0 auto;
    height: 44px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(37, 113, 245, 1);
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #fff;
    text-align: center;
  }
  .MobileElectronicCardWrap .btn + .btn{
    margin-left: 10px;
  }
</style>